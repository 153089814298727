.ws-mycars-nav {
	display: flex;
}

#mycars-mobile-icon-root {
	min-width: auto;
}

.mycars-nav-badge {
	position: absolute;
	border-radius: 50%;
	top: 4px;
	right: -3px;
	background: var(--btn-link-color);
}

.mycars-nav-badge--desktop {
	@extend .mycars-nav-badge;

	width: 8px;
	height: 8px;
}

.mycars-nav-badge--mobile {
	@extend .mycars-nav-badge;

	width: 6px;
	height: 6px;
}

.modal-nav-link--desktop {
	min-height: 53px;
	line-height: 1.7;
}
